// import Admin from "./Pages/Admin/App"
import Alumni from "./Pages/App"
// import Public from "./Pages/Public/App"

function App() {
  return (
      // <Admin />
      <Alumni />
      // <Public />÷\
  );
}
export default App;
